<template>
  <div class="maincontainer">
    <joymew-header></joymew-header>
    <banner></banner>
    <div class="adaptivePlace">
      <div class="title">适用场景</div>
      <div class="adativeItems">
        <div class="adativeItem">
          <img
            src="@/assets/newhome/adaptivePlace3.png"
            alt="嗨喵悦动,嗨喵互动,婚庆相亲"
            srcset=""
          />
          <div :class="[place2 == true ? 'subtitle1' : 'subtitle']">婚庆相亲</div>
        </div>
        <div class="adativeItem">
          <div class="itemimg"></div>
          <div :class="[place1 == true ? 'subtitle1' : 'subtitle']">企业年会</div>
        </div>

        <div class="adativeItem">
          <img
            src="@/assets/newhome/adaptivePlace1.png"
            alt="嗨喵悦动,嗨喵互动,酒吧户外"
            srcset=""
          />
          <div :class="[place3 == true ? 'subtitle1' : 'subtitle']">酒吧户外</div>
        </div>
        <div class="adativeItem">
          <img
            src="@/assets/newhome/adaptivePlace2.png"
            alt="嗨喵悦动,嗨喵互动,企业营销"
            srcset=""
          />
          <div :class="[place4 == true ? 'subtitle1' : 'subtitle']">企业营销</div>
        </div>
      </div>
    </div>
    <div class="adativeItemscontainer">
      <!-- 企业年会 -->
      <div class="items" v-if="place1">
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 8.png')"
            alt="年会现场使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 17.png')"
            alt="企业年会使用酒店大屏玩互动游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 18.png')"
            alt="企业员工扫码签到祝福年会圆满成功"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 19.png')"
            alt="嗨喵大屏互动让企业年会更精彩"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 20.png')"
            alt="年会现场使用嗨喵互动摇一摇游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 21.png')"
            alt="年会使用嗨喵大屏互动抽奖功能游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 22.png')"
            alt="数钞票互动游戏让年会现场更有趣"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/partyPic/Rectangle 23.png')"
            alt="年会来宾使用手机玩嗨喵互动游戏"
            srcset=""
          />
        </div>
      </div>
      <!-- 婚庆相亲 -->
      <div class="items" v-if="place2">
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 8.png')"
            alt="婚礼现场使用大屏互动红包雨游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 17.png')"
            alt="结婚使用酒店大屏玩互动游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 18.png')"
            alt="现场来宾扫码签到祝福新婚快乐"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 19.png')"
            alt="嗨喵大屏互动让婚礼现场更欢乐"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 20.png')"
            alt="婚礼现场使用嗨喵互动摇一摇游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 21.png')"
            alt="婚礼使用嗨喵大屏互动抽奖游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 22.png')"
            alt="追新娘互动游戏让结婚现场更有趣"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/weddingPic/Rectangle 23.png')"
            alt="婚礼现场宾客使用手机玩嗨喵互动游戏"
            srcset=""
          />
        </div>
      </div>
      <!-- 酒吧户外 -->
      <div class="items" v-if="place3">
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 8.png')"
            alt="潮流酒吧使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 17.png')"
            alt="酒吧现场使用大屏玩互动游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 18.png')"
            alt="酒吧顾客扫码签到表达欢乐心情"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 19.png')"
            alt="嗨喵大屏互动让酒吧现场更精彩"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 20.png')"
            alt="酒吧现场使用嗨喵互动摇一摇游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 21.png')"
            alt="酒吧使用嗨喵大屏互动抽奖功能游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 22.png')"
            alt="数钞票互动游戏让酒吧现场更有趣"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/outsidePic/Rectangle 23.png')"
            alt="酒吧顾客使用手机玩嗨喵互动游戏"
            srcset=""
          />
        </div>
      </div>
      <!-- 企业营销 -->
      <div class="items" v-if="place4">
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 8.png')"
            alt="商演庆典现场使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 17.png')"
            alt="庆典峰会使用大屏玩互动游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 18.png')"
            alt="庆典来宾扫码签到祝福活动圆满成功"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 19.png')"
            alt="嗨喵大屏互动让企业营销会更精彩"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 20.png')"
            alt="产品推介会现场使用嗨喵互动摇一摇游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 21.png')"
            alt="庆典峰会使用嗨喵大屏互动抽奖功能游戏"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 22.png')"
            alt="数钞票互动游戏让庆典现场更有趣"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/marketingAct/Rectangle 23.png')"
            alt="营销庆典现场来宾使用手机玩嗨喵互动游戏"
            srcset=""
          />
        </div>
      </div>
    </div>
    <div class="fundation">
      <div class="title">基础功能</div>
      <div class="fundationItems">
        <div class="fundationItem">
          <div class="subtitle">16类互动玩法，37项核心功能。全面覆盖活动发布、签到、活动、数据</div>
        </div>
      </div>
    </div>

    <div class="fundationItemscontainer">
      <div class="items">
        <div class="itemcontainer">
          <div class="item">
            <img v-lazy="require('../assets/gamePic/scanSign.gif')" alt="微信扫码签到" srcset="" />
          </div>
          <div class="title">微信扫码签到</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/particleLottery.gif')"
              alt="活动名单抽奖"
              srcset=""
            />
          </div>
          <div class="title">活动名单抽奖</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/shakeRedEnvelopes.gif')"
              alt="手机摇红包雨游戏"
              srcset=""
            />
          </div>
          <div class="title">手机摇红包雨游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/whoIsBest.gif')"
              alt="手机摇一摇游戏"
              srcset=""
            />
          </div>
          <div class="title">手机摇一摇游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/countMoney.gif')"
              alt="手机数钞票游戏"
              srcset=""
            />
          </div>
          <div class="title">手机数钞票游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/dargonFlyInSky.gif')"
              alt="龙年专属飞龙在天游戏"
              srcset=""
            />
          </div>
          <div class="title">龙年专属飞龙在天游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/dragonPlaybead.gif')"
              alt="龙年专属游龙戏珠游戏"
              srcset=""
            />
          </div>
          <div class="title">龙年专属游龙戏珠游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/signLottery.gif')"
              alt="活动微信扫码签到抽奖"
              srcset=""
            />
          </div>
          <div class="title">活动微信扫码签到抽奖</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/guessMovie.gif')"
              alt="趣味猜电影互动游戏"
              srcset=""
            />
          </div>
          <div class="title">趣味猜电影互动游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img v-lazy="require('../assets/gamePic/vote.gif')" alt="活动节目投票功能" srcset="" />
          </div>
          <div class="title">活动节目投票功能</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/giantScreenLottery.gif')"
              alt="3D巨幕抽奖游戏"
              srcset=""
            />
          </div>
          <div class="title">3D巨幕抽奖游戏</div>
        </div>
        <div class="itemcontainer">
          <div class="item">
            <img
              v-lazy="require('../assets/gamePic/chaseBride.gif')"
              alt="摇一摇追新娘游戏"
              srcset=""
            />
          </div>
          <div class="title">摇一摇追新娘游戏</div>
        </div>
      </div>
    </div>
    <div class="knowMore">
      <a class="knowMore" href="https://mp.weixin.qq.com/s/OW4MafDjOO8awQRHR0HEQg" target="_blank"
        >了解更多</a
      >
    </div>

    <div class="whyChooseTitle">为什么选择嗨喵？</div>
    <div class="optionsIntroduction">
      <div class="statible">
        <div class="cover">
          <div class="title">专业稳定</div>
          <!-- <p style="font-weight:5000">专业稳定</p> -->
          <div class="comment">
            高性能服务器，超稳超安全 专业工程师、客服人员24小时在线 领先对手不止一步
          </div>
        </div>
      </div>
      <div class="convience">
        <div class="cover">
          <div class="title">操作便捷</div>
          <div class="comment">
            操作简单，极致体验，一分钟创建活动。一台联网电脑，一部手机 轻松应对各种场合
          </div>
        </div>
      </div>
      <div class="allfree">
        <div class="cover">
          <div class="title">全部免费</div>
          <div class="comment">18款互动玩法不重样，专业功能 全部免费！免费！免费！</div>
        </div>
      </div>
    </div>
    <div class="classicalCases">经典案例</div>
    <div class="classicalContainer">
      <div class="classicalItem">
        <div class="bg">
          <img
            v-lazy="require('../assets/newhome/Rectangle 31.png')"
            alt="2020年第二届安徽主持人交流峰会使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="title" style="margin-left: -20px">2020年第二届安徽主持人交流峰会</div>
      </div>
      <div class="classicalItem">
        <div class="bg">
          <img
            v-lazy="require('../assets/newhome/Rectangle 33.png')"
            alt="2021嗨喵与宁波主持团队战略签约仪式"
            srcset=""
          />
        </div>
        <div class="title">2021嗨喵宁波战略签约仪式</div>
      </div>
      <div class="classicalItem">
        <div class="bg">
          <img
            v-lazy="require('../assets/newhome/Rectangle 35.png')"
            alt="嗨喵互动参加世界华人结婚产业颁奖盛典"
            srcset=""
          />
        </div>
        <div class="title">世界华人结婚产业颁奖盛典</div>
      </div>
    </div>
    <div class="partnership">嗨喵合伙人</div>
    <div class="partnershipComment">
      <span class="main">携手、聚势、合作、共赢，嗨喵悦动感谢每一位合伙人老师的付出。</span>
      <!-- <span class="clickKnow" @click="navTo" >了解嗨喵合伙人</span> -->
      <!-- <router-link class="clickKnow" target="_blank" to='https://mp.weixin.qq.com/s/jDPuF_BMUU7ykQTw1X-94Q'>了解嗨喵合伙人</router-link> -->
      <a class="clickKnow" target="_blank" href="https://mp.weixin.qq.com/s/jDPuF_BMUU7ykQTw1X-94Q"
        >了解嗨喵合伙人</a
      >
    </div>
    <div class="partnershipitems">
      <div v-lazy class="box">
        <div class="item">
          <div class="name">小坤哥</div>
          <div class="name1"></div>
          <!-- <img
            style="height: 100%"
            src="../assets/hostPIc/25071622011987_.pic.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">刘奎</div>
          <div class="name2"></div>
          <!-- <img src="../assets/hostPIc/WechatIMG1779.png" alt="" srcset="" /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">杨乐</div>
          <div class="name3"></div>
          <!-- <img src="../assets/hostPIc/1622013368576.jpg" alt="" srcset="" /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">吴刚</div>
          <div class="name4"></div>
          <!-- <img
            src="../assets/hostPIc/24991622011944_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">上小官</div>
          <div class="name5"></div>
          <!-- <img src="../assets/hostPIc/shangxiaoguan.jpeg" alt="" srcset="" /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">上官</div>
          <div class="name6"></div>
          <!-- <img src="../assets/hostPIc/shangguan.jpeg" alt="" srcset="" /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">阿四</div>
          <div class="name7"></div>
          <!-- <img src="../assets/hostPIc/asi.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">李照</div>
          <div class="name8"></div>
          <!-- <img
            src="../assets/hostPIc/24901622011938_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">陈宇</div>
          <div class="name9"></div>
          <!-- <img
            src="../assets/hostPIc/24921622011940_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">天宇</div>
          <div class="name10"></div>
          <!-- <img
            src="../assets/hostPIc/24911622011939_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">陈建桥</div>
          <div class="name11"></div>
          <!-- <img src="../assets/hostPIc/WechatIMG2500.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">申喆</div>
          <div class="name12"></div>
          <!-- <img
            src="../assets/hostPIc/24971622011943_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">小凡</div>
          <div class="name13"></div>
          <!-- <img
            src="../assets/hostPIc/24931622011941_.pic.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">周锋</div>
          <div class="name14"></div>
          <!-- <img src="../assets/hostPIc/WechatIMG2529.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">东明</div>
          <div class="name15"></div>
          <!-- <img src="../assets/hostPIc/dongming.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">刘贝</div>
          <div class="name16"></div>
          <!-- <img
            src="../assets/hostPIc/24961622011942_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">海琳</div>
          <div class="name17"></div>
          <!-- <img src="../assets/hostPIc/WechatIMG2510.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">一欣</div>
          <div class="name18"></div>
          <!-- <img src="../assets/hostPIc/chenyixin.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">萝卜哥</div>
          <div class="name19"></div>
          <!-- <img
            src="../assets/hostPIc/24981622011943_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">凯钰</div>
          <div class="name20"></div>
          <!-- <img src="../assets/hostPIc/kaiyu.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">宣岩</div>
          <div class="name21"></div>
          <!-- <img
            src="../assets/hostPIc/25031622011945_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">天悦</div>
          <div class="name22"></div>
          <!-- <img
            src="../assets/hostPIc/25051622011946_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">马蕊</div>
          <div class="name23"></div>
          <!-- <img src="../assets/hostPIc/WechatIMG1787.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">孟良</div>
          <div class="name24"></div>
          <!-- <img
            src="../assets/hostPIc/25041622011946_.pic.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">秋傲</div>
          <div class="name25"></div>
          <!-- <img src="../assets/hostPIc/qiuao.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">张鑫瑞</div>
          <div class="name26"></div>
          <!-- <img src="../assets/hostPIc/WechatIMG2920.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">黄恒</div>
          <div class="name27"></div>
          <!-- <img
            src="../assets/hostPIc/25011622011945_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">振国</div>
          <div class="name28"></div>
          <!-- <img src="../assets/hostPIc/zhenguo.jpeg" alt="" srcset="" /> -->
          <div class="title">合伙人</div>
        </div>
        <div class="item">
          <div class="name">小坤哥</div>
          <div class="name1"></div>
          <!-- <img
            style="height: 100%"
            src="../assets/hostPIc/25071622011987_.pic.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">刘奎</div>
          <div class="name2"></div>
          <!-- <img src="../assets/hostPIc/WechatIMG1779.png" alt="" srcset="" /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">杨乐</div>
          <div class="name3"></div>
          <!-- <img src="../assets/hostPIc/1622013368576.jpg" alt="" srcset="" /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">吴刚</div>
          <div class="name4"></div>
          <!-- <img
            src="../assets/hostPIc/24991622011944_.pic_hd.jpg"
            alt=""
            srcset=""
          /> -->
          <div class="title">高级合伙人</div>
        </div>
        <div class="item">
          <div class="name">上小官</div>
          <div class="name5"></div>
          <!-- <img src="../assets/hostPIc/shangxiaoguan.jpeg" alt="" srcset="" /> -->
          <div class="title">高级合伙人</div>
        </div>
      </div>
    </div>
    <div class="customercorpContainer">
      <div class="title">合作客户</div>
      <div class="customercorp">
        <div class="item">
          <img
            src="../assets/corPic/WeChatImage_20210612154320.jpg"
            alt="百年老字号同庆楼与嗨喵达成战略合作"
            class="customercorp-n"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/b98abc1e5e2b67b0b0484a93cdcdffa8.jpg')"
            alt="Host主持团队使用嗨喵大屏互动"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/c41f6748cedc533b50e9361f124c3e17.jpg')"
            alt="建桥主持团队使用嗨喵主持婚礼活动"
            srcset=""
            class="customercorp-n"
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/d94a88b74ee8ba241e8ac9c250f8e2cd.jpg')"
            alt="浙江珍爱主持团队与嗨喵互动达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/dab6a21bf4a7056412c2eaf1186b029e.jpg')"
            alt="婚尚荟婚庆公司与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/e34c509c8f7bdda24ee25a5b59d89a16.jpg')"
            alt="领秀品牌与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/efa95de713f8fa8108bb534c0114a6fd.jpg')"
            alt="游羊婚嫁与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/WechatIMG76.jpg')"
            alt="南京瑞庭婚礼宴会中心与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/WechatIMG77.jpg')"
            alt="艾薇拉婚礼会馆与嗨喵达成合作"
            srcset=""
          />
        </div>
        <div class="item">
          <img
            v-lazy="require('../assets/corPic/WechatIMG78.jpeg')"
            alt="晨丰主持人工作室与嗨喵达成合作"
            srcset=""
          />
        </div>
      </div>
    </div>
    <div class="map">
      <div class="infoContainer">
        <div class="title">联系我们</div>
        <div class="info">
          <div class="left">
            <div class="title1">嗨喵悦动——让互动更精彩</div>
            <div class="detail">
              <div class="address">地址：上海普陀区中江路118号</div>
              <div class="tel">电话：400-082-2298</div>
              <div class="e-mail">邮箱：bd@hudongmiao.com</div>
            </div>
          </div>
          <div class="right">
            <div class="rightTitle">关注我们</div>
            <div class="qrPlace">
              <img
                v-lazy="require('@/assets/image/Code2.jpg')"
                class="qrcodeImg"
                alt="嗨喵悦动,嗨喵互动,公众号"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <myFooter />

    <!-- <toolBox @openQrcodeBox="openQrcodeBox" /> -->
    <qrcodeBox @closeQrcodeBox="closeQrcodeBox" v-if="qrcodeBoxVisible" />
  </div>
</template>

<script>
import banner from '@/components/layout/banner.vue';
import joymewHeader from '@/components/layout/joymewHeader.vue';
import myFooter from '@/components/myFooter.vue';

// import toolBox from '@/components/toolBox.vue';
import qrcodeBox from '@/components/qrcodeBox.vue';

export default {
  components: {
    banner,
    joymewHeader,
    myFooter,
    // toolBox,
    qrcodeBox,
  },
  methods: {
    navTo() {},

    handleMessage(e) {
      // console.log(e);
      if (e.data === 'needLogin') {
        this.openLrBox(0);
      }
    },
    closeQrcodeBox() {
      this.qrcodeBoxVisible = false;
    },
    openQrcodeBox() {
      this.qrcodeBoxVisible = true;
    },
    watchScroll() {
      window.addEventListener('scroll', this.handleScroll);
    },
    show1() {
      this.place1 = false;
      this.place2 = true;
      this.place3 = false;
      this.place4 = false;
    },
    show2() {
      this.place1 = true;
      this.place2 = false;
      this.place3 = false;
      this.place4 = false;
    },
    show3() {
      this.place1 = false;
      this.place2 = false;
      this.place3 = true;
      this.place4 = false;
    },
    show4() {
      this.place1 = false;
      this.place2 = false;
      this.place3 = false;
      this.place4 = true;
    },
    showevent(i) {
      if (i === 0) {
        this.show1();
      } else if (i === 1) {
        this.show2();
      } else if (i === 2) {
        this.show3();
      } else if (i === 3) {
        this.show4();
      }
    },
  },
  created() {
    // pcIndex = 0;
    // loopLock = false;
    // pcLen = this.praiseContentList.length;
  },
  mounted() {
    this.watchScroll();
    // this.initStatisticsNumAni();
    // const video2 = document.getElementById('myVideo');
    const video2 = document.getElementsByTagName('video');

    for (let i = 0; i < video2.length; i += 1) {
      video2[i].controls = false;
    }
    const adaptiveTitle = document.getElementsByClassName('adativeItem');
    for (let i = 0; i < adaptiveTitle.length; i += 1) {
      console.log(i);
      adaptiveTitle[i].addEventListener('mouseover', () => {
        this.showevent(i);
      });
    }
  },
  data() {
    return {
      place2: true,
      place1: false,
      place3: false,
      place4: false,

      qrcodeBoxVisible: false,
    };
  },
};
</script>

<style lang="less" scoped>
// .maincontainer {
//     font-size: ;
// }
video {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
}
video:hover {
  // height: 500px;
  transform: scale(1.2);
}
.wrapper {
  // height: 879px;
  .carouselImg {
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}

.adaptivePlace {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
  margin-bottom: 30px;
  .title {
    color: rgba(51, 51, 51, 1);
    font-size: 36px;
    margin-bottom: 13px;
    font-weight: bold;
  }
  .adativeItems {
    width: 1328px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.6);
    height: 80px;
    padding: 0 100px;
    // .adativeItem:hover {
    //     color: rgba(255,204,79,1);
    // }
    .adativeItem {
      display: flex;
      align-items: center;
      // justify-content: center;
      // width: 100%;
      cursor: pointer;

      .itemimg {
        width: 48px;
        height: 48px;
        background: url("../assets/newhome/adaptivePlace4.png") no-repeat;
        background-position: 99% 100%;
        background-size: 545px 1202px;
      }
      img {
        width: 48px;
        height: 48px;
      }
      .subtitle {
        color: rgba(0, 0, 0, 0.6);
        // color: #000;
        text-align: center;
        width: 100%;
        font-size: 28px;
      }
      .subtitle1 {
        color: rgba(255, 204, 79, 1);
        // color: #000;
        text-align: center;
        width: 100%;
        font-size: 28px;
      }
      .subtitle:hover {
        color: rgba(255, 204, 79, 1);
      }
    }
  }
}
.adativeItemscontainer {
  height: 616px;
  // background: red;
  background: url("../assets/newhome/adaptiveBg.png") no-repeat;
  background-size: 100%;
  padding-top: 84px;
  .items {
    flex-wrap: wrap;
    display: flex;
    // margin: 29px auto;
    // margin-left: 149px;
    // width: 100%;
    // padding: 60px 178px;
    // padding-left: 160px;
    margin-left: 149px;
    margin-top: 29px;
    .item {
      width: 20%;
      height: 197px;
      background: rgba(196, 196, 196, 1);
      margin-left: 55px;
      margin-bottom: 55px;
    }
  }
}
.fundationItemscontainer {
  // height: 616px;
  background: url("../assets/newhome/waterCloud.png") no-repeat;
  background-size: 15%;
  background-position: 0px 200px;
  .items {
    flex-wrap: wrap;
    display: flex;
    margin: 29px auto;
    margin-left: 149px;
    // padding: 60px 178px;
    // width: 100%;
    .itemcontainer {
      width: 20%;
      // height: 197px;
      // background: rgba(196,196,196,1);
      // margin: 55px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 55px;
      // margin-right: 27.5px;
      margin-left: 55px;
      .item {
        width: 100%;
        height: 197px;
        // background: rgba(196, 196, 196, 1);
        // margin: 27.5px;
        // margin-bottom: 55px;
        // // margin-right: 27.5px;
        // margin-left: 55px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        margin-top: 20px;
        color: rgba(51, 51, 51, 1);
        font-size: 24px;
      }
    }
  }
}
.fundation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
  .title {
    color: rgba(51, 51, 51, 1);
    font-size: 36px;
    margin-bottom: 13px;
    font-weight: bold;
  }
  .fundationItems {
    width: 1128px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.6);
    height: 80px;
    .fundationItem {
      display: flex;
      align-items: center;
      // justify-content: center;
      width: 100%;
      img {
        width: 48px;
        height: 48px;
      }
      .subtitle {
        color: rgba(0, 0, 0, 0.6);
        // color: #000;
        text-align: center;
        width: 100%;
        font-size: 28px;
      }
    }
  }
}
.knowMore {
  width: 201px;
  height: 54px;
  background: rgba(255, 204, 79, 1);
  border-radius: 8px;
  line-height: 54px;
  text-align: center;
  margin: 0 auto;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
}
.whyChooseTitle {
  color: rgba(51, 51, 51, 1);
  font-size: 36px;
  text-align: center;
  margin-top: 97px;
  font-weight: bold;
}
.optionsIntroduction {
  height: 368px;
  // background: red;
  display: flex;
  // position: relative;
  // div{
  //     width: 33.3333%;
  //     background: pink;
  // }
  margin-top: 48px;
  .statible {
    position: relative;
    width: 33.3333%;
    // background: pink;
    background: url("~@/assets/newhome/bg1.jpg") no-repeat center;
    // background-position: center 0px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // padding-top: 92px;
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 36px;
        font-weight: bold;
        margin-top: 92px;
        // position: absolute ;
        // margin: 0 auto;
      }
      .comment {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        margin: 10px 138px;
        font-weight: 400;
        // position: absolute;
        // margin-top: 150px;
      }
    }
  }
  .convience {
    width: 33.3333%;
    // background: rgba(0,0,0,.4);
    background: url("~@/assets/newhome/bg2.jpg") no-repeat center;
    // background-position: center 0px;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // padding-top: 92px;
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 36px;
        font-weight: bold;
        padding-top: 92px;
        // position: absolute;
      }
      .comment {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        margin: 10px 138px;
        font-weight: 400;
        // position: absolute;
        // margin-top: 150px;
      }
    }
  }
  .allfree {
    width: 33.3333%;
    // background: pink;
    background: url("~@/assets/newhome/bg3.jpg") no-repeat center;
    // background-position: center 0px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    // padding-top: 92px;
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 36px;
        font-weight: bold;
        margin-top: 92px;
        // position: absolute;
      }
      .comment {
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        margin: 10px 138px;
        font-weight: 400;
        // position: absolute;
        // margin-top: 150px;
      }
    }
  }
}
.classicalCases {
  color: rgba(51, 51, 51, 1);
  font-size: 36px;
  text-align: center;
  margin-top: 97px;
  font-weight: bold;
  margin-bottom: 48px;
}
.classicalContainer {
  height: 468px;
  background-color: rgba(250, 249, 248, 1);
  display: flex;
  justify-content: space-between;
  padding-top: 48px;
  padding-left: 178px;
  padding-right: 178px;
  .classicalItem {
    background-color: rgba(255, 255, 255, 1);
    width: 490px;
    height: 372px;
    .bg {
      width: 485px;
      height: 258px;
      // background-color: pink;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 28px;
      text-align: center;
      padding: 28px 60px;
      white-space: nowrap;

      //   text-align: center;
    }
  }
}
.partnership {
  color: rgba(51, 51, 51, 1);
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-top: 96px;
  margin-bottom: 32px;
}
.partnershipComment {
  text-align: center;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.6);
  padding-bottom: 16px;
  width: 65%;
  margin: 0 auto;
  .main {
    color: rgba(0, 0, 0, 0.6);
    font-size: 28px;
  }
  .clickKnow {
    color: rgba(255, 204, 79, 1);
    font-size: 28px;
    border-bottom: 1px solid;
  }
}
.partnershipitems {
  display: flex;
  margin: 0 178px;
  margin-top: 31px;
  // width: 1580px;
  overflow: hidden;
  // flex-wrap: wrap;
  .box:hover {
    animation-play-state: paused;
    -webkit-animation-play-state: paused;
  }
  .box {
    animation: rolling 50s linear infinite;
    // position: absolute;
    display: flex;
    .item:nth-child(2n) .name {
      background: rgba(255, 204, 79, 1);
      color: rgba(255, 255, 255, 1);
    }
    .item {
      width: 297px;
      height: 396px;
      //   background-color: pink;
      margin-right: 20px;
      display: flex;
      text-align: center;
      flex-direction: column;
      margin-bottom: 100px;
      // 8316
      .name {
        height: 66px;
        line-height: 66px;
        color: rgba(51, 51, 51, 1);
        background-color: rgba(228, 232, 233, 1);
        font-weight: bold;
        font-size: 28px;
      }
      .name1 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25071622011987_.pic.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name2 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG1779.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name3 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/yl.jpg) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name4 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24991622011944_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name5 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/shangxiaoguan.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name6 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/shangguan.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name7 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/asi.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name8 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24901622011938_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name9 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24921622011940_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name10 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24911622011939_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name11 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG2500.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name12 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24971622011943_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name13 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24931622011941_.pic.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name14 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG2529.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name15 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/dongming.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name16 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24961622011942_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name17 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG2510.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name18 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/yixinNew.jpg) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name19 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/24981622011943_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name20 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/kaiyu.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name21 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25031622011945_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name22 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25051622011946_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name23 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG1787.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name24 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25041622011946_.pic.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name25 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/qiuao.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name26 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/WechatIMG2920.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name27 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/25011622011945_.pic_hd.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      .name28 {
        width: 100%;
        height: 100%;
        background: url(../assets/hostPIc/zhenguo.png) no-repeat;
        background-size: 100%;
        // background-position: center;
      }
      img {
        display: block;
        // flex: 1;
        // width: 100%;
        height: 370px;
        // height: 100%;
      }
      .title {
        height: 66px;
        line-height: 66px;
        color: rgba(51, 51, 51, 1);
        background-color: rgba(228, 232, 233, 1);
        font-size: 28px;
      }
    }
  }
  @keyframes rolling {
    // from {
    //     transform: translateX(0);
    // }

    // to {
    //     transform: translateX(-50%);
    // }8856 7291
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(-8856px, 0, 0);
      transform: translate3d(-8856px, 0, 0);
    }
  }
}
.customercorpContainer {
  height: 927px;
  background-color: rgba(28, 35, 57, 1);
  .title {
    color: rgba(255, 255, 255, 1);
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin-top: 96px;
    padding-top: 96px;
    padding-bottom: 48px;
  }
  .customercorp {
    margin: 0 170px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .item {
      // text-align: center;
      display: flex;
      align-items: center;
      width: 290px;
      height: 297px;
      background-color: #ffffff;
      margin-right: 20px;
      margin-bottom: 20px;
      .customercorp-n {
        object-fit: contain;
      }
      // .customercorp2 {
      //   width: 100%;
      //   height: 100%;
      //   background: url(../assets/corPic/c41f6748cedc533b50e9361f124c3e17.jpg) no-repeat;
      //   background-size: 140%;
      //   background-position: center;
      // }
      img {
        height: 100%;
      }
    }
  }
}
.map {
  height: 758px;
  background: url("~@/assets/newhome/map.jpg") no-repeat;
  background-size: 100%;
  padding: 176px 426px;
  .infoContainer {
    height: 406px;
    width: 1068px;
    background: rgba(255, 255, 255, 1);
    // margin: 176px 426px;
    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 36px;
      font-weight: bold;
      text-align: center;
      padding: 48px 0;
    }
    .info {
      display: flex;
      // width: 100%;
      justify-content: center;
      .left {
        // width: 50%;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0 102px;
        .title1 {
          font-weight: bold;
          font-size: 28px;
          color: rgba(51, 51, 51, 1);
        }
        .detail {
          color: rgba(51, 51, 51, 1);
          font-size: 20px;
        }
      }
      .right {
        width: 49%;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 28px;
        font-weight: bold;
        .qrPlace {
          width: 100px;
          height: 100px;
          // background: pink;
          margin-top: 15px;
        }
      }
    }
  }
}
.moreDetail {
  padding: 32px 200px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  .item {
    .title {
      color: rgba(51, 51, 51, 1);
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .detail {
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
      div {
        margin: 8px 0;
      }
    }
  }
}
.bottomLogo {
  width: 459px;
  height: 226px;
  margin: 25px auto;
}
.bottom {
  height: 172px;
  background: rgba(250, 249, 248, 1);
  text-align: center;
  .visionRight {
    color: rgba(255, 204, 79, 1);
    font-size: 20px;
    font-weight: bold;
    padding: 24px 0;
  }
  .huICP {
    display: flex;
    justify-content: center;
    .left {
      margin-right: 48px;
    }
  }
}
.tips {
  width: 100px;
  height: 300px;
  // background: #333;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  // border: 1px solid #000;
  background: #fff;
  position: fixed;
  top: 742px;
  right: 56px;
  z-index: 99999;
  div {
    width: 100px;
    height: 100px;
  }
  div {
    img {
      width: 30px;
      height: 30px;
    }
    .detail {
      // display: inline-block;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style lang="less">
.el-menu--popup {
  // width: 150px;
  min-width: unset;
  border-radius: 8px;
  background-color: #ffffff;
  justify-content: flex-start;
  .sItem {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #fc9632;
    }
    .versionIcon {
      margin-right: 4px;
      margin-top: -2px;
    }
  }
}
</style>
