<template>
  <div class="wrapper">
    <!-- <el-carousel height="4.57813rem"> -->
    <el-carousel height="3.2rem">
      <el-carousel-item v-for="item in carouseList" :key="item.id">
        <!-- <div
          class="carouselImg"
          @click="toLink(item.link)"
          :style="{ backgroundImage: 'url(' + item.path + ')' }"
        ></div> -->
        <img
          :src="item.path"
          class="carouselImg"
          alt="嗨喵悦动,嗨喵互动,banner"
          @click="toLink(item.link)"
        />
      </el-carousel-item>
    </el-carousel>
    <lrBox @close="handleCloseLrBox" v-show="lrBoxVisible" ref="lrBoxDom" />
  </div>
</template>

<script>
import { getCarouselList } from '@/api/index';
import store from '@/store/index';
import lrBox from '@/components/lrBox.vue';

export default {
  components: {
    lrBox,
  },
  data() {
    return {
      carouseList: [
        // {
        //   id: '2',
        //   path: require('@/assets/image/bannerApp.jpg'),
        //   link: 'https://mp.weixin.qq.com/s/wl0CWceQUDluvGFpUTv9tA',
        // },
        {
          id: '4',
          path: require('@/assets/image/banner1new.jpg'),
          link: 'https://mp.weixin.qq.com/s/OW4MafDjOO8awQRHR0HEQg',
        },
        {
          id: '3',
          path: require('@/assets/image/bar_banner.png'),
          link: 'https://mp.weixin.qq.com/s/P9jWGdojuhTLvwM0gPFtTg',
        },
      ],
      lrBoxVisible: false,
    };
  },
  created() {
    getCarouselList()
      .then((res) => {
        console.log('轮播图:', res);
        this.carouseList = res.list.map((item) => ({
          path: item.bg_link,
          id: item.id,
          link: item.redirect || '',
        }));
        this.showKefuScript();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    showKefuScript() {
      /* eslint-disable */
      const _53code = document.createElement("script");
      _53code.src = "https://tb.53kf.com/code/code/2c891055109c51301f8b2e9df6910cdf1/1";
      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(_53code, s);
    },
    toLink(link) {
      if (link === "yx") {
        if (store.state.token) {
          window.location.href = `${window.location.origin}/hm-customer/index.html#/app/yx`;
        } else {
          this.openLrBox(0);
        }
      } else if (link) {
        window.open(link, "_blank");
      }
    },
    openLrBox(type) {
      this.lrBoxVisible = true;
      this.$nextTick(() => {
        this.$refs.lrBoxDom.openBoxByType(type);
      });
    },
    handleCloseLrBox() {
      this.lrBoxVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  // height: 879px;
  .carouselImg {
    // height: 100%;
    // background-size: cover;
    // background-size: 100% 100%;
    // background-position: 50% 50%;
    // background-repeat: no-repeat;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
</style>
